@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,300;0,400;0,700;1,200;1,500&display=swap');

@font-face{
  font-family: cantata;
  src:url(./cantata-one.regular.ttf);
}

html{
  margin:0;
  font-size: 16px;
  background-color:#fff;
}
body{
  font-family:'Josefin Slab', serif;
  font-weight:1000;
  margin:0;
  /* background-color: #002B5B; */

}

/* <-------NAVBAR----------> */

.navbar{
  padding:0 !important;
  /* margin:0 !important; */
}

.logo{
  /* border:black solid 1px; */
  margin-top:0;
  margin-left:8rem;
  margin-right:1.5rem;
  width:8.5rem;
  height:6.5rem;
}
.nav--bar a{
  /* margin:top right bottom left */
  margin:0 3.5rem 0 3.5rem ;
  text-decoration: none;
  color:#002B5B !important;
  font-size: small;
  font-weight:600; 
} 
.nav--bar a:hover{
  color:#189ad3 !important;
}
.navbar-collapse{
  justify-content: normal;
}
.active a{
  color:#189ad3 !important;;
}

/* <-------MAIN IMAGE----------> */
.carousel-caption {
  bottom: 2px !important;
  z-index: 2 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  
}
.carousel-indicators {
  bottom: 1px !important;
  z-index: 1 !important;
}  
.carousel-caption h5{
  font-family:cantata;
  font-size: 1.2rem;
  font-weight: 800;
  color:white;
  text-shadow: 2px 2px #026d98;
  letter-spacing: .1em;
}
.carousel-caption p{
  font-family:cantata;
  font-size: 1rem;
  font-weight: 800;
  color:white;
  text-shadow: 2px 2px #026d98;
  letter-spacing: .1em;
}
.carousel-caption h6{
  list-style-type: none;
  text-decoration: none;
}

.carousel-caption a{
  background-color:#84d0f8 ;
  list-style-type: none;
  text-decoration: none;
  padding:.4rem;
  font-size: 1em;
  color:white !important;
  border-radius: 5%;
}

/* ----------------responsive for carousel------------------- */
@media all and (max-width:770px){
  .carousel-caption h5,.carousel-caption h6,.carousel-caption p,.carousel-caption a{
    font-size:.5em;
  }
}
@media all and (max-width:400px){
  .carousel-caption h5,.carousel-caption h6,.carousel-caption p,.carousel-caption a{
    font-size:.4em;
  }
}


/* <-------MAIN IMAGE END----------> */

.how--container{
  margin-top:0.6rem;
  margin-left:auto;
  margin-right:auto;  
  width:99%;
  height:50%;
  text-align: center;
}
.how--container--heading h2{
  color:#002B5B;
  margin:0;
  padding:0;
  font-family: cantata;
  font-size: medium;
  font-weight: 100;
}

.how--it--works{
  margin-top: 1.2rem;
  margin-bottom: 0;
  margin-left:auto;
  margin-right:auto; 
  width:99%;
  height:50%;
  text-align: center;
  background-color: #9ccae9;
}
.how--it--works a:hover{
  color:#189ad3;
}
.cards{
  display:flex;
  margin-left: auto;
  margin-right: auto;
  width:63%;
  height:50%;
}
.cards h3{
  padding:1rem;
  color:white;
  font-family: cantata;
  font-weight: 1;
  font-size: 1rem;
}
.card--1,.card--2,.card--3{
  margin-top:1.5rem;
  width:20rem;
  height:17rem;
  justify-items: center;
  /* text-align: center; */
}
.card--2{
  border-top: solid white 0.5rem;
  border-bottom: solid white 0.5rem;
  border-left:solid white 0.1rem;
  border-right: solid white 0.1rem; 
}
.card--1, .card--3{
  background-image: url("./images/how_1_img.jpg");
  background-size: cover;
  border: solid white 0.5rem;
}
.card--1 h3,.card--3 h3{
  color:black;
}
.card--2--front{
  background-image: url("./images/how_2_img.jpg");
  background-size: cover;
  opacity:0.8;
}
.how--two--icons img,.how--card--3--icons img,.how--card--2--icons img{
  margin:1rem;
  border-radius: 50%;
}

.card--1,.card--2,.card--3{
  position:relative;
  transition: transform 3s ease;
  transform-style: preserve-3d;
}
.card--1:hover,.card--2:hover,.card--3:hover{
  transform:rotateY(.5turn);
}
.card--1--front,.card--1--back,.card--2--front,.card--2--back,.card--3--front,.card--3--back{
  justify-items: center;
  align-items: center;
  text-align: center;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  backface-visibility:hidden;
  transform-style: preserve-3d;
}
.card--1--back,.card--2--back,.card--3--back{
  transform:rotateY(.5turn);
  background: white;
  list-style-type: none;  
}
.card--1--back h2,.card--2--back h2,.card--3--back h2{
  /* padding:1rem; */
  margin-top:1rem;
  margin-bottom:1rem;
  color:#002B5B;
  font-size:1rem;
}
.card--1--back p,.card--2--back p,.card--3--back p{
  font-family: cantata;
  color:#09315e;
  font-size: .8rem;
  margin:.5rem;
  font-weight: 10;
  line-height:2rem;
}
.card--1--back a,.card--2--back a,.card--3--back a{
  text-decoration: none;
  color:#72C3EF;
  font-weight: 100;
}

.card--2--back span{
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr;
  margin:0;
  padding:0;  
}

/* ----------------------------card responsiveness------------------- */
@media all and (max-width:1150px){
  .how--two--icons img,.how--card--2--icons img,.how--card--3--icons img{
    margin:0.1rem;
  }

}
 @media all and (max-width:1080px){
  .how--two--icons,.how--card--2--icons,.how--card--3--icons{
    display:grid;
    justify-items: center;
    grid-template-columns: 1fr;
  }
  .cards img{ 
    margin:0.2rem;
    margin-top:0.4rem;
    margin-bottom: 0.4rem;
    width:3rem;
    height:3rem;
  }
  .cards h3,.cards p,.cards h2{
    font-size:.7rem;
    line-height:1rem;
  }
}

@media all and (max-width:490px){
  .cards h3,.cards p,.cards h2{
    font-size:.6rem;
  }
}
@media all and (max-width:400px){
  
  .cards h3{
    font-size: .5rem;
  }
  .cards p{
    font-size:.5rem;
  }
}
@media all and (max-width:310px){
  
  .cards h3,.cards p,.cards h2{
    font-size: .4rem;
  }
  .cards img{ 
    margin:0.2rem;
    margin-top:0.4rem;
    margin-bottom: 0.4rem;
    width:2.5rem;
    height:2.5rem;
  }
}

/* --------------end of cards responsiveness------------------ */
/*------------------------- Book a consult page -------------------------------------------  */
.bookaconsult{
  margin-top:1rem;
}
.lets--begin--container{
  text-align: center;
}
.lets--begin--container h2{
  color:#002B5B;
  margin:0;
  padding:0;
  font-family: cantata;
  font-size: medium;
  font-weight: 100;
}
.lets--begin--container h4{
  font-size: 1rem;
  text-decoration: none;
  color:#002B5B;
  opacity: 0.7;
  padding:0;
}
.lets--begin{
  display:grid;
  grid-template-columns: .5fr 1fr;
  background-image: url('./images/form_bg.jpg');
  background-size: cover;
  background-blend-mode: overlay;
  background-color:rgba(122, 180, 220, 0.6);
}

.lets--begin--header{
  background-image: url('./images/book\ a\ consult.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  
}
.lets--form--1{
  background-color:rgba(122, 180, 220, 0.1);
  border: solid white 0.2rem;
  border-radius: 5%;
  width:70%;
}
.form--name--mob{
  /* border: solid black 1px; */
  display:grid;
  padding:0.3rem;
  text-align: left;
}
.form--name--mob label{
  font-family: cantata;
  color:#002B5B;
  font-weight:10;
  font-size: 0.9em;
}
.form--name--mob ::placeholder,.form--comments ::placeholder{
  color:#002B5B;
  font-size:0.7em;
  font-weight:300;
}
#name{
  width:45%;
}
#mobile{
  width:45%;
}
.form--checkbox,.form--radios{
  text-align: left;
  padding:0.3rem;
}

.form--checkbox label,.form--radios label{
  color:#002B5B;
  font-family: cantata;
  font-weight: 590;
  font-size: 0.8em;
  margin:0.3rem;  
}
.form--comments{
  display: flex;
  text-align: left;

}
.lets--form--1 h3{
  font-size: 1em;
  font-weight: 400;
  color:#002B5B;
}
.form--button{
  text-align: center;
}
#submit{
  margin:0.6rem;
  text-align:center;
  color:white;
  font-family: cantata;
  background-color:#189ad3;
  border:white solid 0.1rem;
  border-radius: 5%;
}
#success{
  margin-bottom: 1rem;
  width:100vw;
  height:7vh;
  font-family: cantata;
  /* background-color:#9ad4ed; */
  background-image: linear-gradient(to bottom right, rgba(126, 232, 250, 0.4), rgba(238, 192, 198, 0.4));
  display:none;
  color:#0274a1;
  text-align: center;
  line-height: 2;
}
#success h6{
  font-size: small;
  line-height: 1.5;
  word-spacing:0.4rem;
}

/* ------------book a consult responsiveness---------------- */
@media all and (max-width:700px){
  .lets--begin--header img{
    margin-top:55%;
    width:25%;
    height:30%;
    border-radius: 5%;
  }
}
@media all and (max-width:500px){
  #success h6{
    padding:.1rem;
    font-size: .7rem;
    word-spacing:0.4rem;
  }
  .lets--form--1{
    font-size: .5rem;
  }
}
@media all and (max-width:450px){
  .lets--form--1{
    font-size: .2rem;
  }
  .lets--begin--header img{
    margin-top:65%;
    width:20%;
    height:20%;
    border-radius: 5%;
  }
}
/* ---------all four sections------------ */
.foursection{
  border:.5rem white solid;
}
/*------------ SKIN FORM ------------------*/
.skin--section,.hair--section,.dental--section,.nutrition--section{
  margin-top:1rem;
  margin-bottom:1rem;
  width:100%;
  height:100%;
  display:grid;
  grid-template-columns: 18% 7% 37% 37%;
  grid-template-areas: 
  'g1 g2 g3 g4'
  'g1 g2 g5 g6'
  ;
  list-style: none;
}
.grid-item-1{
  grid-area: g1;
  border-radius:5%;
  background-color: rgba(33, 158, 188, .4);
  box-shadow: -2px -2px 5px white, 2px 2px 5px rgba(23, 126, 152, 0.4);
  background-image: url("./images/skinwellness.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height:30rem;
}

.grid-item-2,.h-grid-item-2,.d-grid-item-2,.n-grid-item-2{
  border:.1rem white solid;
  display:grid;
  grid-area: g2;
  height:30rem;
  background-color: rgba(33, 158, 188, .4);
  border-radius:5%;
  text-align: center;
  justify-items: center;
  align-items: center;
}
.grid-item-3{
  grid-area: g3;
  background-image: url(./images/skin_t_img.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position:right; 
  background-color: rgba(33, 158, 188, .4);
  border-radius:5%;
}
.grid-item-4{
  grid-area: g4;
  background-image: url(./images/product1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius:5%;
}
.grid-item-5{
  grid-area: g5;  
  background-image: url(./images/highlight.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}
.grid-item-6{
  grid-area:g6;
  background-image: url(./images/book_4.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}

.grid-item-1 a,.grid-item-2 a,.grid-item-3 a,.grid-item-4 a,.grid-item-5 a,.grid-item-6 a,
.h-grid-item-1 a,.h-grid-item-2 a,.h-grid-item-3 a,.h-grid-item-4 a,.h-grid-item-5 a,.h-grid-item-6 a,
.d-grid-item-1 a,.d-grid-item-2 a,.d-grid-item-3 a,.d-grid-item-4 a,.d-grid-item-5 a,.d-grid-item-6 a,
.n-grid-item-1 a,.n-grid-item-2 a,.n-grid-item-3 a,.n-grid-item-4 a,.n-grid-item-5 a,.n-grid-item-6 a{
  text-decoration: none;
}
.grid-item-1 h3,.grid-item-2 h3,.grid-item-3 h3,.grid-item-4 h3,.grid-item-5 h3,.grid-item-6 h3,
.h-grid-item-1 h3,.h-grid-item-2 h3,.h-grid-item-3 h3,.h-grid-item-4 h3,.h-grid-item-5 h3,.h-grid-item-6 h3,
.d-grid-item-1 h3,.d-grid-item-2 h3,.d-grid-item-3 h3,.d-grid-item-4 h3,.d-grid-item-5 h3,.d-grid-item-6 h3,
.n-grid-item-1 h3,.n-grid-item-2 h3,.n-grid-item-3 h3,.n-grid-item-4 h3,.n-grid-item-5 h3,.n-grid-item-6 h3{
  font-weight: 600;
  color:#FDFEFF;
  font-family: cantata;
  font-size: 1rem;
  text-shadow:1.5px 1px #0976a9;
  border-radius: 10%;
  padding:.5rem;
  width:50%;
  height:8%;
}

.skin--form--block a{
  font-family: cantata;
  font-size: 1rem;
  color:white;
  text-shadow: 2px 2px #0b6893;
  font-weight: 600;
}
.skin--form--block a:hover{
  color:#189ad3;
  text-shadow: 2px 2px white;
}
.our--favourites--caption,.our--highlights--caption,.our--bookings--caption{
  color:white;
  font-weight:800;
  font-size:.7rem;
  font-family: cantata;
  width:100%;
  background-color: rgba(33, 158, 188, .6);
  border-radius:5%;
  text-align: center; 
}
.grid-item-4,.h-grid-item-4,.d-grid-item-4,.n-grid-item-4{
  position: relative;
}
.our--favourites--caption{
  position: absolute;
  bottom: 0%;
}
.grid-item-5,.h-grid-item-5,.d-grid-item-5,.n-grid-item-5{
  position: relative;
}
.our--highlights--caption{
  position: absolute;
  bottom: 0%;
}
.grid-item-6,.h-grid-item-6,.d-grid-item-6,.n-grid-item-6{
  position:relative;
}
.our--bookings--caption{
  position: absolute;
  bottom: 0%;
}

/* -----------------------hair section---------------------- */
.h-grid-item-1{
  grid-area: g1;
  border-radius:5%;
  background-color: rgba(33, 158, 188, .4);
  box-shadow: -2px -2px 5px white, 2px 2px 5px rgba(23, 126, 152, 0.4);
  background-image: url("./images/haircare.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height:30rem;
}

.h-grid-item-3{
  grid-area: g3;
  background-image: url("./images/hair_t_img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position:right; 
  background-color: rgba(33, 158, 188, .4);
  border-radius:5%;
}
.h-grid-item-4{
  background-color: rgba(33, 158, 188, .4);
  grid-area: g4;
  background-image: url(./images/product3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius:5%;
  background-position:center;
}
.h-grid-item-5{
  grid-area: g5;  
  background-image: url(./images/highlight2222.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}
.h-grid-item-6{
  grid-area:g6;
  background-image: url(./images/book_4.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}

/* -------------------------dental section-------------------------------- */
.d-grid-item-1{
  grid-area: g1;
  border-radius:5%;
  background-color: rgba(33, 158, 188, .4);
  box-shadow: -2px -2px 5px white, 2px 2px 5px rgba(23, 126, 152, 0.4);
  background-image: url(./images/dentalcare.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height:30rem;
}

.d-grid-item-3{
  grid-area: g3;
  background-image: url("./images/dental_t_img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position:right; 
  background-color: rgba(33, 158, 188, .4);
  border-radius:5%;
}
.d-grid-item-4{
  background-color: rgba(33, 158, 188, .4);
  grid-area: g4;
  background-image: url(./images/product4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius:5%;
  background-position:center;
}
.d-grid-item-5{
  grid-area: g5;  
  background-image: url(./images/highlight3.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
  background-position:right;
}
.d-grid-item-6{
  grid-area:g6;
  background-image: url(./images/book_4.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}
/* --------------------------------NUTRITION SECTION----------------------------------------- */
.n-grid-item-1{
  grid-area: g1;
  border-radius:5%;
  background-color: rgba(33, 158, 188, .4);
  box-shadow: -2px -2px 5px white, 2px 2px 5px rgba(23, 126, 152, 0.4);
  background-image: url(./images/nutritionmanagement.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height:30rem;
  grid-area: g1;
  /* background-image: url(./images/nutrition_t_img.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position:right;  */
}

.n-grid-item-3{
  grid-area: g3;
  background-image: url("./images/nutrition_t_img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position:right; 
  background-color: rgba(33, 158, 188, .4);
  border-radius:5%;
}
.n-grid-item-4{
  background-color: rgba(33, 158, 188, .4);
  grid-area: g4;
  background-image: url(./images/product5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius:5%;
  /* background-position:center; */
}
.n-grid-item-5{
  grid-area: g5;  
  background-image: url(./images/Highlight5.jpg);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
  background-position:center;
}
.n-grid-item-6{
  grid-area:g6;
  background-image: url(./images/book_4.png);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius:5%;
}


/*----------------------- Responsive four sections------------------------------- */

 @media all and (max-width:700px){
  .skin--form--block a{
    font-size:.3rem;
  }
 }
@media all and (max-width:500px){
  .grid-item-1 h3,.grid-item-2 h3,.grid-item-3 h3,.grid-item-4 h3,.grid-item-5 h3,.grid-item-6 h3,
  .h-grid-item-1 h3,.h-grid-item-2 h3,.h-grid-item-3 h3,.h-grid-item-4 h3,.h-grid-item-5 h3,.h-grid-item-6 h3,
  .d-grid-item-1 h3,.d-grid-item-2 h3,.d-grid-item-3 h3,.d-grid-item-4 h3,.d-grid-item-5 h3,.d-grid-item-6 h3,
  .n-grid-item-1 h3,.n-grid-item-2 h3,.n-grid-item-3 h3,.n-grid-item-4 h3,.n-grid-item-5 h3,.n-grid-item-6 h3{
    font-size: .7rem;
  }
  .our--favourites--caption,.our--highlights--caption,.our--bookings--caption{
    font-size: .5rem;
  }
 }
 @media all and (max-width:480px){

  .grid-item-1,.grid-item-2,
  .h-grid-item-1,.h-grid-item-2,
  .d-grid-item-1,.d-grid-item-2,
  .n-grid-item-1,.n-grid-item-2{
    height:100%;
  }  
  .grid-item-4,.grid-item-5,.grid-item-6,
  .h-grid-item-4,.h-grid-item-5,.h-grid-item-6,
  .d-grid-item-4,.d-grid-item-5,.d-grid-item-6,
  .n-grid-item-4,.n-grid-item-5,.n-grid-item-6{
    height:100%;
    background-size: cover;
  }
  .grid-item-1 h3,.grid-item-2 h3,.grid-item-3 h3,.grid-item-4 h3,.grid-item-5 h3,.grid-item-6 h3,
  .h-grid-item-1 h3,.h-grid-item-2 h3,.h-grid-item-3 h3,.h-grid-item-4 h3,.h-grid-item-5 h3,.h-grid-item-6 h3,
  .d-grid-item-1 h3,.d-grid-item-2 h3,.d-grid-item-3 h3,.d-grid-item-4 h3,.d-grid-item-5 h3,.d-grid-item-6 h3,
  .n-grid-item-1 h3,.n-grid-item-2 h3,.n-grid-item-3 h3,.n-grid-item-4 h3,.n-grid-item-5 h3,.n-grid-item-6 h3{
    font-size: .6rem;
  }
  .our--favourites--caption,.our--highlights--caption,.our--bookings--caption{
    font-size: .4rem;
  }
 }
@media all and (max-width:380px){
  .skin--form--block a{
    font-size:.2rem;
  }
  .grid-item-1 h3,.grid-item-2 h3,.grid-item-3 h3,.grid-item-4 h3,.grid-item-5 h3,.grid-item-6 h3,
  .h-grid-item-1 h3,.h-grid-item-2 h3,.h-grid-item-3 h3,.h-grid-item-4 h3,.h-grid-item-5 h3,.h-grid-item-6 h3,
  .d-grid-item-1 h3,.d-grid-item-2 h3,.d-grid-item-3 h3,.d-grid-item-4 h3,.d-grid-item-5 h3,.d-grid-item-6 h3,
  .n-grid-item-1 h3,.n-grid-item-2 h3,.n-grid-item-3 h3,.n-grid-item-4 h3,.n-grid-item-5 h3,.n-grid-item-6 h3{
    font-size: .5rem;
  }
  .our--favourites--caption,.our--highlights--caption,.our--bookings--caption{
    font-size: .3rem;
  }
}
/*--------------------- Social icon bar------------- */
.icon-bar{
  position: fixed;
  top: 50%;
  right:0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
 .icon-bar a {
  width:3.5rem;
  height:3.5rem;
  display: block;
  text-align: center;
  padding: 1rem;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
} 

.icon--insta,.icon--phone,.icon--email,.icon--whatsapp {
  background: #56a9d0;
  color: white;
  border: white solid 1px;
  border-radius:30%;
}
.icon-bar a:hover {
  background-color:#3ea2d1;
} 
.icon-bar i:hover {
  background-color:#3ea2d1;
} 

/* --------------Responsive Social Icons---------------------- */
@media all and (max-width:950px){
  .icon-bar a {
    width:2.5rem;
    height:2.5rem;
    padding:.5rem;
}
}
/* ------------------------Footer ----------------------*/
.footer{
  display:flex;
  /* border: solid black 1px; */
  flex-wrap: wrap;
  justify-content:space-evenly;
  margin-top:0.5rem;
  /* margin-bottom:0.5rem; */
  background-color:rgba(112, 214, 255, .3) ;
  color:black;
}
.help,.contact--us,.address{
  
  padding:1rem;
  text-align: center;
}

.contact--links{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:3rem;
  padding-bottom:1rem;
  color:#72C3EF;

}

.contact--links a, .help a{
  padding:0.6rem 0.8rem 0.6rem 0.8rem ;
  margin:.1rem;
  text-decoration: none;
  /* color:bisque; */
  color:black;

}


#help--p,#contactus--p,#address--p {
  border-bottom:lightskyblue solid 0.01rem;
  /* border-bottom-width:0.1rem; */
}

.help a:hover{
  color:#0274a1;
}

.copyright{
  margin:0;
  padding:0;
  text-align: center;
  background-color:rgba(112, 214, 255, .3) ;
}
.copyright a{
  text-decoration: none;
  color:#0274a1;
}
/* --------------------------TREATMENTS PAGE--------------------------------- */
.t--heading{
  font-size: 1.5rem;
  color:#002B5B;
  opacity:0.95;
  text-align: center;
  background-color: #9ccae9;
}
.treatments--container{
  margin-top:1rem;
}
section{
  position: relative;
  overflow:hidden;
}
section .wave{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:1.2rem;
  background: url(./images/wave.png);
  background-size: 1000px 1.2rem;
}
section .wave.waves{
  animation: animate 5s linear infinite;
  opacity:0.8;
}
@keyframes animate
{
  0%
  {
    background-position-x:0;
  }
  100%
  {
    background-position-x:1000px;
  }

}
.t--subheading{
  font-size: 1.2em;
  color:#416893;
  opacity:0.9;
  padding:1rem;
  text-align: center;
}

.skin--t--container{
  margin:0;
  padding:0;
  display:grid;
  grid-template-columns: 1fr;
  /* height: 100%; */
  background-color: rgba(156, 202, 233, 0.3);
  text-align: center;
  justify-items: center;
  align-items: center;
}
.hair--t--container{
  margin:0;
  padding:0;
  display:grid;
  grid-template-columns: 1fr;
  /* height: 100%; */
  background-color: rgba(175, 232, 244,0.5);
  justify-items: center;
  text-align: center;
  align-items: center;
}
.dental--t--container{
  margin:0;
  padding:0;
  display:grid;
  grid-template-columns: 1fr;
  /* height: 100%; */
  background-color: rgba(143, 174, 213, 0.4);
  justify-items: center;
  text-align: center;
  align-items: center;

}
.nutrition--t--container{
  margin:0;
  padding:0;
  /* margin-top:1rem; */
  display:grid;
  grid-template-columns: 1fr;
  /* height: 100%; */
  background-color: rgba(66, 220, 255, 0.25);
  justify-items: center;
  text-align: center;
  align-items: center;
}


.skin--first{
  border:solid white 2px;
  border-radius: 50%;
  margin-top: 1rem;
  margin-bottom: 0;
  padding:1rem;
  width:10rem;
  height:12rem;
  /* text-align: center; */
  background-image: url("./images/about_skin.png");
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center;
  background-color:rgba(156, 202, 233, 0.2);
}
.hair--first{
  border:solid white 2px;
  border-radius: 50%;
  margin-top: 1rem;
  margin-bottom: 0;
  padding:1rem;
  width:10rem;
  height:12rem;
  /* text-align: center; */
  background-image: url("./images/about_hair.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color:rgba(156, 202, 233, 0.2);
}
.dental--first{
  overflow: hidden;
  border:solid white 2px;
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
  padding:1rem;
  width:10rem;
  height:12rem;
  /* text-align: center; */
  background-image: url("./images/about_dentl.png");
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-color:rgba(156, 202, 233, 0.2);
}
.nutrition--first{
  border:solid white 2px;
  border-radius: 50%;
  margin-top: 1rem;
  padding:1rem;
  width:10rem;
  height:12rem;
  /* text-align: center; */
  background-image: url("./images/about_nutri.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  background-color:rgba(156, 202, 233, 0.2);
}


.treatment--list--container{
  /* border:1px solid black; */
  margin:0;
  padding:0;
  display:flex;
  /* height:100%; */
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
}
.treatment--list{
  /* border: 1px solid red; */
  /* margin:.5rem; */
  padding:1rem;
  width:40.5%;
  height: 9%;
}
.img--icon--s{
  border:#002B5B solid 1px;
  border-radius: 50%;
  /* margin:0.3rem; */
  width:4rem;
  /* float:left; */
}
.s--t--heading{
  /* float:right; */
  font-size: 1em;
  color:#09315e;
  opacity:0.9;
  margin:0;
  padding:0;
  text-align: center;
}
.s--t--explanation{
  font-family: cantata;
  font-size:0.9em;
  color:#09315e;
  padding:0.2rem;
  font-weight: 500;
  text-align: center;
}
.t--book{
  width:99vw;
  height:5vh;
  text-align: center;
  list-style-type: none;
  margin:1rem 0rem 1rem 0rem;
  background-color: rgba(178, 231, 255, 0.6);

}
.t--book a{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: large;
  text-decoration: none;
  padding:.8rem 1.5rem .8rem 1.5rem;
  background-color: #b2e7ff;
  color:#09315e;
  border-radius: 10%;
  margin:0;
}
/* -------------------- treatments page responsiveness */
@media all and (max-width:700px){
  .treatment--list{
    width:100%;
  }
  .t--subheading{
    font-size:1rem ;
  }
  .s--t--heading{
    font-size: .8em;
  }
  .s--t--explanation{
    font-size:.7em;
  }
  
}

/* ----------------------ABOUT US PAGE------------------------------------- */
.a--heading{
  font-family:cantata;
  font-size:2rem;
  word-spacing:0.3rem;
  text-align: center;
  /* font-weight:50; */
  margin:1rem 4rem 0rem 4rem;
}
.a--heading h6{
  color: #cc67ad;
}
.a--heading p{
  line-height: 1.5rem;
  font-family:cantata;
  font-size: small  ;
  color:#cc67ad;
}
.heart {
  /* padding:1rem; */
  fill: red;
  margin:.1rem;
  position: relative;
  /* top: 5px; */
  width: 1.2rem;
  animation: pulse 5s ease infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}
.a--subheading{
  color: #0274a1;
  font-size:1.5rem;
  word-spacing:0.3rem;
  text-align: center;
  font-family: cursive;
}
.a--img{
  margin-top:1rem;
  display: flex;
  justify-content: center;
  background-image: url('./images/about_us_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}
.a--img img{
  border-radius:50%;
  margin:.1rem;
  width:10rem;
  height:12rem;
}
.a--intro{
  color:rgb(71, 136, 197);
  font-family: cantata;
  font-size:1.5rem;
  margin:.1rem;
  text-align: center;
  font-weight: 50;
}
.a--team{
  color:#002B5B;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  font-size:.8rem;  
}
.a--values{
  margin:.1rem;
  padding: 0;
  background-size:auto;
  display:grid;
  grid-template-columns: .1fr 1fr;
  align-items: center;
}
.a--values--heading{
  background-image: url("./images/about_us.jpeg");
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 3rem;
  color:#0274a1;

}
.values{
  background-size: contain;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.val{
  margin:.1rem;
  border-radius: 5%;
  text-align: center;
}
.val p{
  font-family: Arial, Helvetica, sans-serif;
  font-size:0.8rem;
  font-weight:540;
  color:#002B5B;
}
.value_1{
  background-image: linear-gradient(to bottom right, rgba(126, 232, 250, 0.4), rgba(238, 192, 198, 0.4));
}
.value_2{
  background-image: linear-gradient(to bottom right, rgba(58, 237, 207, 0.4), rgba(238, 192, 198, 0.4));
}

.value_3{
  background-image: linear-gradient(to bottom right, rgba(126, 232, 250, 0.4), rgba(171, 233, 205, 0.4));
}
.value_4{
  background-image: linear-gradient(to bottom right, rgba(131, 234, 241, 0.8), rgba(238, 192, 198, 0.8));
}
.value_5{
  background-image: linear-gradient(to bottom right, rgba(126, 232, 250, 0.7), rgba(99, 164, 255, 0.7));
}
.value_6{
  background-image: linear-gradient(to bottom right, rgba(99, 164, 255, 0.7), rgba(1, 186, 239, 0.7));
}
/* ----------------------About us page responsiveness------------------ */
/* @media all and (max-width:600px){
  .a--heading h6,.a--heading p,.a--subheading,.a--intro,.a--team{
    font-size:.8rem;
  }
  .a--img img{
    width:6rem;
    height:8rem;
  }
}
@media all and (max-width:300px){
  .a--heading h6,.a--heading p,.a--subheading,.a--intro,.a--team{
    font-size:.6rem;
  }
  .heading1{
    font-size:.6rem;
  }
  .p-quote1{
    font-size:.5rem;
  }
} */
/* ----------------------------ALL FOUR FORMS--------------------------------- */
/* ---------------------------------SKIN FORM------------------------------- */
.skin--form{
  background: #2980B9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:#2980B9;
  text-align: center;
}
.skin--form--container,.hair--form--container,.dental--form--container,.nutrition--form--container{
  padding:1rem;
}
.skin--form--container h2,.hair--form--container h2,.dental--form--container h2,.nutrition--form--container h2{
  text-shadow: 2px 2px white;
}
.skin--form--container h6,.dental--form--container h6,.nutrition--form--container h6{
  text-shadow: 2px 2px skyblue;
  padding:1rem;
}
#skin--success{
  background: #2980B9; 
  color:white;
  font-size: medium;
}
.skin--form--container{
  color:#0274a1;  
}
.skin--form--list,.hair--form--list,.dental--from--list,.nutrition--form--list{
  margin:1rem;
}
.skin--form--radios,.hair--form--radios,.dental--form--radios,.nutrition--form--radios{
  margin:1rem;
}
.gender{
  margin-top:1.5rem;
  display:flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.gender h6{
  margin-right:2rem;
}
.gender label{
  margin-left:1rem;
  margin-right:1rem;
}
.skin--treatments--select,.hair--treatments--select,.dental--treatments--select,.nutrition--treatments--select{
  display: flex;
  justify-content: center;
  margin:1rem;
}
.skin--form--comments,.hair--form--comments,.dental--form--comments,.nutrition--form--comments{
  display: flex;
  justify-content: center;
  margin-top:1rem 0 .5rem 0;
}
.skin--form--comments textarea, .hair--form--comments textarea,.dental--form--comments textarea,.nutrition--form--comments textarea{
  width:25rem;
  height:5rem;
}
.skin--curr input,.hair--curr input,.dental--curr input,.nutrition--curr input{
  width:100%;
}

/* ---------------------------------hair Form---------------------- */
.hair--form{
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:#0a6d86;
  text-align: center;  
}
.hair--form--container h6{
  padding:1rem;
  text-shadow: 1px 1px #7ccce0;
}
.hair--form--button #submit{
  background-color:#2193b0;
}
/* --------------------------------dental form----------------------- */
.dental--form{
  background: #B2FEFA;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0ED2F7, #B2FEFA);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0ED2F7, #B2FEFA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:#1f908a;
  text-align: center; 
}
.dental--form--button #submit{
  background-color:#33c0b9;
}
/* ----------------nutrition form----------------------------- */
.nutrition--form{
  background: #74ebd5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ACB6E5, #74ebd5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ACB6E5, #74ebd5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color:#0d7260; 
  text-align: center;
}
.nutrition--form--button #submit{
  background-color:#33c0b9;
}

/* ---------------------responsiveness of seperate forms-------- */
@media all and (max-width:700px){
  .skin--form--container,.skin--form--container h6,.hair--form--container,.hair--form--container h6,
  .dental--form--container,.dental--form--container h6,.nutrition--form--container,.nutrition--form--container h6{
    font-size:1rem;
  }
}
@media all and (max-width:500px){
  .skin--form--container,.skin--form--container h6,.hair--form--container,.hair--form--container h6,
  .dental--form--container,.dental--form--container h6,.nutrition--form--container,.nutrition--form--container h6{
    font-size:.5rem;
  }
}
@media all and (max-width:300px){
  .skin--form--container,.skin--form--container h6,.hair--form--container,.hair--form--container h6,
  .dental--form--container,.dental--form--container h6,.nutrition--form--container,.nutrition--form--container h6{
    font-size:.2rem;
  }
}